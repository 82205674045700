<template>
    <div class="change-all m-scroll">
        <HeaderBar title="Switch Accounts">
            <span class="top-right" @click="manage = !manage" slot="right">
                {{!manage ? 'Manage' : 'Done'}}
            </span>
        </HeaderBar>
        <div class="shopping-main">
            <!-- 支付方式 -->
            <div class="change-box">
                <div class="change-item" v-for="(item, index) in changeList" :key="index" :class="{'change-active' : changeActive == index}"
                @click="changeActive = index">
                    <img :src="item.img" alt="">
                    <div>
                        <p>
                            {{item.name}}
                            <img src="@/assets/leftbar/icon_xingbie@2x.png" alt="">
                        </p>
                        <p>{{item.name}}</p>
                    </div>
                    <img src="@/assets/payment/select.png" alt="" v-if="!manage">
                    <img src="@/assets/payment/active.png" alt="" v-if="!manage">
                    <span>
                        <img src="@/assets/icon_delet@2x.png" alt="" v-if="manage">
                    </span>
                </div>
            </div>
        </div>

        <div class="addAccount" v-if="!manage">Switch Account</div>
        <div class="addAccount add" v-if="manage">Add Account</div>
    </div>
</template>

<script>
import HeaderBar from '@/components/en/m-header.vue'
export default {
    components: { HeaderBar },
    data() {
        return {
            changeList: [
                {name: 'zhanghaoone', img: require('@/assets/fork-imgs/shop04.jpg')},
                {name: 'twozhanghao', img: require('@/assets/fork-imgs/shop04.jpg')},
                {name: 'zhanghaothree', img: require('@/assets/fork-imgs/shop04.jpg')}
            ],
            changeActive: 0,
            manage: false
        }
    },
    methods: {
        calcOrder(e) {
            // 提交订单
            e.target.classList.add('calc-order-scale')
            setTimeout(() => {
                e.target.classList.remove('calc-order-scale')
            }, 150)

            // 支付
            this.$router.push('/en/changestatus')
        }
    },
    created() {
        
    }
}
</script>

<style scoped lang="less">
.change-all{padding-top:40px;
    .change-box{margin:15px;width:calc(100% -30px);background-color: #fff;padding:0 15px;
        .change-item{width:100%;height:71px;border-bottom:1px solid #f1f1f1;position: relative;line-height: 71px;
            >img{
                &:nth-of-type(1){width:40px;height:40px;margin-right:10px;vertical-align: -12px;border-radius: 50%;}
                &:nth-of-type(2){width:18px;height:18px;margin-right:0px;vertical-align: -8px;position: absolute;right:0;top:25px;}
                &:nth-of-type(3){width:18px;height:18px;margin-right:0px;vertical-align: -8px;position: absolute;right:0;top:25px;display: none;}
            }
            >div{display: inline-block;height:100%;vertical-align: -9px;
                p{display: block;height:20px;line-height: 20px;}
                p:nth-child(1){
                    font-size: 16px;
                    
                    font-weight: 500;
                    color: #000000;
                    img{width:5vw;height:5vw;display: inline-block;vertical-align: middle;}
                }
                p:nth-child(2){
                    font-size: 14px;
                    
                    font-weight: 300;
                    color: #666666;
                }
            }
            >span>img{
                width:18px;height:18px;margin-right:0px;vertical-align: -8px;position: absolute;right:0;top:25px;
            }
        }
        .change-active{
            img:nth-of-type(2){display: none;}
            img:nth-of-type(3){display: block;}
        }
    }
    .addAccount{
        width: 300px;
        height: 44px;
        background: linear-gradient(315deg, #F03C18 0%, #F06448 100%);
        border-radius: 22px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 44px;
        letter-spacing: 1px;
        margin:40px auto
    }
    .add{background: linear-gradient(315deg, #4d8adf 0%, #2A65BA 100%);}
    .top-right {font-size: 12px;font-weight:600;color: #2A65BA;}
}
</style>

<style lang="less">
.change-all{
    .step-total{width:80px;height:20px;margin-right:-10px;
        button,input{height:100%;border-radius: 0}
        input{border:1px solid rgba(230,230,230,1);background-color: #fff;margin:0}
        button{width:20px;background-color: rgba(230,230,230,1)}
    }
    .m-cat-padding{padding:0}
    .m-cat-devider{
        margin:15px 0;
        font-size:18px;
        font-weight:500;
        color:rgba(15,30,51,1);
    }
}
</style>
